<style>
  @import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
</style>

<template>
  <div id="app">
    <component :is="layout">

    </component>
  </div>
</template>



<script>
const default_layout = "fullwidth";

export default {
  name: 'Nav',
  computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    }
}
</script>

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html.has-navbar-fixed-top {padding-top:68px !important;}

.sidebar-container {background:#F9F9F9;border-right:1px solid #ddd}
.sticky-sidebar {position:sticky;top:68px;}
</style>