import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	
	  async getUser(accessToken, user_id) {
		let res = await axios.get(API_NAME + "/users/" + user_id + "?field=id", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  
  
}