<template>
    <div class="  is-fullheight sticky-sidebar" >
      <aside class="menu column  aside   is-fullheight" >
       <p class="menu-label">
Applications
      </p>
       <ul class="menu-list">
         <li>
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = '/applications'"><router-link to="/applications"  :class="{'is-active':activeMenuLink === '/applications'}">Dashboard</router-link></li>
             <li @click="activeMenuLink = '/applications/new'"><router-link to="/applications/new"  :class="{'is-active':activeMenuLink === '/applications/new'}">New Applications</router-link></li>
             <li @click="activeMenuLink = '/applications/in_progress'"><router-link to="/applications/in_progress"  :class="{'is-active':activeMenuLink === '/applications/in_progress'}">In Progress</router-link></li>
             <li @click="activeMenuLink = '/applications/open'"><router-link to="/applications/open"  :class="{'is-active':activeMenuLink === '/applications/open'}">Started But Not Submitted</router-link></li>
             <li @click="activeMenuLink = '/applications/waiting'"><router-link to="/applications/waiting" style="display:none" :class="{'is-active':activeMenuLink === '/applications/waiting'}">Waiting on Member</router-link></li>
             <li @click="activeMenuLink = '/applications/interviews'"><router-link to="/applications/interviews" style="display:none" :class="{'is-active':activeMenuLink === '/applications/interviews'}">Interviews</router-link></li>
             <li @click="activeMenuLink = '/applications/training'"><router-link to="/applications/training" style="display:none" :class="{'is-active':activeMenuLink === '/applications/training'}">In Training</router-link></li>
             <li @click="activeMenuLink = '/applications/completed'"><router-link to="/applications/completed" style="display:none" :class="{'is-active':activeMenuLink === '/applications/completed'}">Completed</router-link></li>
             
           </ul>
         </li>

         <li>
           <a class="">Reports</a>
           <ul>
            <li @click="activeMenuLink = '/applications/general-report'"><router-link to="/applications/general-report"  :class="{'is-active':activeMenuLink === '/applications/general-report'}">General Report</router-link></li>
           </ul>
         </li>
         
         
         
       </ul>
       
     </aside>
     
    </div>
 </template>
 
 <script>
 export default {
   name: 'SideBarApplications',
   components: {
   },
   data() {
     return { activeMenuLink: this.$route.path }
   },
   mounted() {
     //this.activeMenuLink = this.$route.path.substring(1)
     this.activeMenuLink = this.$route.path
     console.log(this.activeMenuLink)
   },
   watch: {
    $route () { 
      
      this.activeMenuLink = this.$route.path
    },
  }
   
 }
 
 </script>