import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {layout: "fullwidth"},
     beforeEnter: authGuard 
    
  },
  {
    path: '/members',
    name: 'MembersDashboard',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/Dashboard.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members-by-grade',
    name: 'MembersByGrade',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/MembersByGrade.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/add',
    name: 'AddMember',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/AddMember.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/nmc-check',
    name: 'NmcCheck',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/NmcCheck.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/hcpc-check',
    name: 'HcpcCheck',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/HcpcCheck.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/alerts',
    name: 'Alerts',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/Alerts.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/add-alert',
    name: 'AddAlert',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/AddAlert.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/nmc-report',
    name: 'NmcReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/NmcReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/hcpc-report',
    name: 'HcpcReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/HcpcReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/cop-report',
    name: 'CopReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/CopReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/cos-report',
    name: 'CosReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/CosReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/visa-report',
    name: 'VisaReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/VisaReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/niscc-report',
    name: 'NisccReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/NisccReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/last-worked-report',
    name: 'LastWorkedReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/LastWorkedReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/20-hours-report',
    name: '20HoursReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/20HoursReport.vue'),
     beforeEnter: authGuard 
  },
  {
    path: '/members/access-ni-required',
    name: 'MembersAccessNiRequired',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/AccessNiRequired.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/members/training-report',
    name: 'MembersTrainingReport',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/TrainingReport.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/members/list-grades',
    name: 'MembersListGrades',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/ListGrades.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/members/:id',
    name: 'ViewMember',
    meta: {layout: "members"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/ViewMember.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/members/:id',
    name: 'ViewMemberApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "members" */ '../components/Members/ViewMember.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings',
    name: 'BookingsDashboard',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/Dashboard.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/add',
    name: 'AddBooking',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/AddBooking.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/add-block',
    name: 'AddBlockBooking',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/AddBlockBooking.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/new',
    name: 'NewBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/NewBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/assigned',
    name: 'AssignedBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/AssignedBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/pending',
    name: 'PendingBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/PendingBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/cancelled',
    name: 'CancelledBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/CancelledBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/confirmed',
    name: 'ConfirmedBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/ConfirmedBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/monthly-stats',
    name: 'MonthlyBookingStats',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/MonthlyStats.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/monthly-stats-all',
    name: 'MonthlyBookingStatsAll',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/MonthlyStatsAll.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/availability-report',
    name: 'AvailabilityReport',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/AvailabilityReport.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/bookings/search',
    name: 'SearchBookings',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/SearchBookings.vue'),
    beforeEnter: authGuard 
  },
  
  {
    path: '/bookings/:id',
    name: 'ViewBooking',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/ViewBooking.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/patients',
    name: 'ListPatients',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/ListPatients.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/add-patient',
    name: 'AddPatient',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/AddPatient.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/patients/:id',
    name: 'ViewPatient',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/ViewPatient.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/holidays',
    name: 'ListHolidays',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "bookings" */ '../components/Bookings/Holidays.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing',
    name: 'BillingDashboard',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/Dashboard.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/trust-bookings',
    name: 'TrustBookings',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/TrustBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/sage-numbers',
    name: 'SageNumbers',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/SageNumbers.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/missing-references',
    name: 'MissingReferences',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/MissingReferences.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/client-list',
    name: 'ClientList',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ClientList.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/unsent-invoices',
    name: 'UnSentInvoices',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/UnSentInvoices.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/completed-bookings',
    name: 'CompletedBookings',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/CompletedBookings.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/missing-timesheets',
    name: 'MissingTimesheets',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/MissingTimesheets.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/saved-timesheets',
    name: 'SavedTimesheets',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/SavedTimesheets.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/submitted-timesheets',
    name: 'SubmittedTimesheets',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/SubmittedTimesheets.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/approved-timesheets',
    name: 'ApprovedTimesheets',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ApprovedTimesheets.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/processed-timesheets',
    name: 'ProcessedTimesheets',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ProcessedTimesheets.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/payroll-report',
    name: 'PayrollReport',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/PayrollReport.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/member-payroll-summary',
    name: 'MemberPayrollSummary',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/MemberPayrollSummary.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/client-invoices',
    name: 'ClientInvoices',
    meta: {layout: "billing"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ClientInvoices.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/client-groups',
    name: 'ClientGroups',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ClientGroups.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/client-groups/:id',
    name: 'ViewClientGroup',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ViewClientGroup.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/clients',
    name: 'Clients',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/Clients.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/billing/clients/:id',
    name: 'ViewClient',
    meta: {layout: "bookings"},
    component: () => import(/* webpackChunkName: "billing" */ '../components/Billing/ViewClient.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications',
    name: 'ApplicationsDashboard',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/Dashboard.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/new',
    name: 'NewApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/NewApplications.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/open',
    name: 'OpenApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/OpenApplications.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/in_progress',
    name: 'InProgressApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/InProgressApplications.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/waiting',
    name: 'WaitingApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/WaitingApplications.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/interviews',
    name: 'Interviews',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/Interviews.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/training',
    name: 'TrainingApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/Training.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/completed',
    name: 'CompletedApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/CompletedApplications.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/general-report',
    name: 'ApplicationsReport',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/ApplicationsReport.vue'),
    beforeEnter: authGuard 
  },
  {
    path: '/applications/:id',
    name: 'ViewApplications',
    meta: {layout: "applications"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/Applications/ViewApplication.vue'),
    beforeEnter: authGuard 
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
