<script>
import NavBar from "@/components/NavBar";
import SideBarBilling from "@/components/SideBarBilling";
import FooterBar from "@/components/FooterBar";

export default {
  components: {
    NavBar,
    SideBarBilling,
    FooterBar
  }
}
</script>

<style scoped src="@/assets/css/inbox.css">
</style>

<template>
  <div >
    <nav-bar />

    <section class="main-content columns is-fullheight">
			
        <div class="column is-2 sidebar-container"  >
          <SideBarBilling />
        </div>

    
        <div class=" column is-10">
          <div class="section">
            <router-view/>
          
          
          </div>
        </div>
      
		</section>

    

    <footer-bar />

  </div>

</template>

